import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap, switchMap } from 'rxjs/operators';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  private refreshToken(): Observable<string> {
      return this.authService
          .getRefreshToken(this.authService.getResfreshTokenValue())
          .pipe(
              tap((result) => this.authService.setToken(result)),
              map((result) => result.access_token)
          );
  }

  private setAuthorizationHeader(
      request: HttpRequest<any>,
      token: string
  ): HttpRequest<any> {
      return request.clone({
          setHeaders: {
              Authorization: `Bearer ${token}`,
          },
      });
  }

  intercept(
      request: HttpRequest<any>,
      next: HttpHandler
  ): Observable<HttpEvent<any>> {
      if (
          request.url != this.authService.getTokenUri() &&
          request.url != this.authService.getOtherTokenUri() &&
          this.authService.getTokenValue() &&
          this.authService.getOtherTokenValue()
      ) {
          request = this.setAuthorizationHeader(
              request,
              this.authService.getOtherTokenValue()
          );
      }

      return next.handle(request).pipe(
        catchError((responseError: HttpErrorResponse) => {
            if (
                responseError.status === 401 ||
                responseError.status === 403
            ) {
                return this.refreshToken().pipe(
                    switchMap((token) => {
                        request = this.setAuthorizationHeader(
                            request,
                            token
                        );
                        return next.handle(request);
                    }),
                    catchError((err) => {
                        this.authService.userLogout();

                        return throwError(err);
                    })
                );
            }

            return throwError(responseError);
        })
    );


  }
}
