import { DynamicConfig, JwtDecodedModel, OtherTokenResponse, TokenResponse } from './interfaces';
import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { switchMap, tap, take } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import jwt_decode from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private checkAuthentication$: BehaviorSubject<boolean> =
      new BehaviorSubject(this.checkCookie(environment.auth_cookie));
  
  private checkOtherAuthentication$: BehaviorSubject<boolean> =
      new BehaviorSubject(this.checkCookie(environment.otherAuth_cookie));      

  constructor(
      private http: HttpClient,
      private router: Router
  ) {}

  checkCookie(cookieName: string): boolean {
      return localStorage.getItem(cookieName) != null;
  }

  checkAuthentication(): Observable<boolean> {
      return this.checkAuthentication$.asObservable();
  }

  checkOtherAuthentication(): Observable<boolean> {
    return this.checkOtherAuthentication$.asObservable();
}

  redirectLoginPage(): void {
      window.location.href = this.replaceEnvironmentUri(environment.authUri);
  }

  replaceEnvironmentUri(uri:string)
  {
    let replacedUri: string;
    
    replacedUri = uri.replace('{client_id}',this.getClientId());
    replacedUri = replacedUri.replace('{endPointBaseUri}',this.getEndPointBaseUri());
    replacedUri = replacedUri.replace('{realm}',this.getRealm());

    return replacedUri;
  }

  loginUser()
  {
    return this.http.get<DynamicConfig>(environment.authorizerUri)
           .subscribe(data => {
                localStorage.setItem(environment.tokenUri_key, data.tokenUri);
                localStorage.setItem(environment.otherTokenUri_key, data.otherTokenUri);
                localStorage.setItem(environment.refreshTokenUri_key, data.refreshTokenUri);
                localStorage.setItem(environment.endPointBaseUri_key, data.endPointBaseUri);
                localStorage.setItem(environment.realm_key, data.realm);
                localStorage.setItem(environment.clientId_key, data.clientId);
                this.redirectLoginPage();
            });         
  }


  setToken(tokenResponse: TokenResponse): void {

      localStorage.setItem(environment.auth_cookie, tokenResponse.access_token);
      localStorage.setItem(environment.refresh_cookie, tokenResponse.refresh_token);

      this.checkAuthentication$.next(this.checkCookie(environment.auth_cookie));
  }

  setOtherToken(otherTokenResponse: OtherTokenResponse): void {

    localStorage.setItem(environment.otherAuth_cookie, otherTokenResponse.access_token);

    this.checkOtherAuthentication$.next(this.checkCookie(environment.otherAuth_cookie));
}

  getTokenUri():string
  {
    return localStorage.getItem(environment.tokenUri_key) || '';
  }

  getOtherTokenUri():string
  {
    return localStorage.getItem(environment.otherTokenUri_key) || '';
  }

  getRefreshTokenUri():string
  {
    return localStorage.getItem(environment.refreshTokenUri_key) || '';
  }

  getClientId():string
  {
    return localStorage.getItem(environment.clientId_key) || '';
  }

  getEndPointBaseUri():string {
    return localStorage.getItem(environment.endPointBaseUri_key) || '';
  }

  getRealm():string {
    return localStorage.getItem(environment.realm_key) || '';
  }

  getToken(code: string): Observable<TokenResponse> {
      return this.http
          .post<TokenResponse>(this.getTokenUri(), {
              Code: code
          })
          .pipe(take(1));
  }

  getOtherToken(): Observable<OtherTokenResponse> {
    return this.http
        .post<OtherTokenResponse>(this.getOtherTokenUri(), 
          null,
          {
            headers: { 'Authorization': 'Bearer ' + this.getTokenValue() }
          })
        .pipe(take(1));
}

  getTokenValue(): string {
      return localStorage.getItem(environment.auth_cookie) || '';
  }

  getOtherTokenValue(): string {
    return localStorage.getItem(environment.otherAuth_cookie) || '';
}

  getResfreshTokenValue(): string {
      return localStorage.getItem(environment.refresh_cookie) || '';
  }

  getUserData(): JwtDecodedModel | null {
      if (this.getTokenValue()) {
          const tokenDecoded = <JwtDecodedModel>(
              jwt_decode(this.getTokenValue())
          );
          return tokenDecoded;
      }

      return null;
  }

  userLogout(): void {
        
      localStorage.clear();

      this.checkAuthentication$.next(this.checkCookie(environment.auth_cookie));
      this.checkOtherAuthentication$.next(this.checkCookie(environment.otherAuth_cookie));

      this.router.navigate(['home']);
  }

  getRefreshToken(refreshToken: string): Observable<TokenResponse> {
      return of(true).pipe(
          tap(() => localStorage.removeItem(environment.auth_cookie)),
          switchMap(() =>
              this.http.post<TokenResponse>(this.getRefreshTokenUri(), {
                RefreshToken: refreshToken,
              })
          ),
          take(1)
      );
  }
}
