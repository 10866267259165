import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title = 'angular-authentication-example';
  name = "";
  email = "";
  xdsUser = "";
  globalUser = "";
  isUserAuthenticated = false;
  isLoading = true;
  authorizerURI = environment.authorizerUri;

  constructor(private authService: AuthService)
  {}

  ngOnInit() {
    this.loadPage();
  }

  loadPage()
  {
    let userData = this.authService.getUserData();
    if(userData)
    {
      this.isUserAuthenticated = true;
      this.name = userData?.name;
      this.email = userData?.email;
      this.xdsUser = userData?.wam_user?.global;
      this.globalUser = userData?.wam_user?.global;
    }
    else
    {
      this.isUserAuthenticated = false;
      this.name = '';
      this.email = '';
      this.xdsUser = '';
      this.globalUser = '';
    }
    this.isLoading = false;
  }

  onLogin() {
    this.authService.loginUser();
  }

  onLogout() {
    this.authService.userLogout();
    this.loadPage();
  }  

}
