<div class="divContent" *ngIf="!isLoading">

<p>Authorizer URI: {{ authorizerURI }}</p>

<button *ngIf="!isUserAuthenticated" (click)="onLogin()">Login</button>
<button *ngIf="isUserAuthenticated" (click)="onLogout()">Logout</button>
<br/><br/>

<p *ngIf="!isUserAuthenticated">
    <span>Please click on Login button to see the user info</span>
</p>

<table *ngIf="isUserAuthenticated" class="tableUser">
    <thead>
      <th>Name</th>
      <th>E-mail</th>
      <th>XDS USER</th>
      <th>GLOBAL USER</th>
    </thead>
    <tbody>
      <tr>
        <td>{{name}}</td>
        <td>{{email}}</td>
        <td>{{xdsUser}}</td>
        <td>{{globalUser}}</td>
      </tr>
    </tbody>
  </table>

</div>

