import { AuthService } from './../auth.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-login-callback',
  templateUrl: './login-callback.component.html',
  styleUrls: ['./login-callback.component.css']
})
export class LoginCallbackComponent  {

  private subscription: Subscription[] = [];

  constructor(
      private activatedRoute: ActivatedRoute,
      private authService: AuthService,
      private router: Router,
  ) {
      this.subscription.push(
          this.activatedRoute.queryParams
              .pipe(
                  map((params) => params['code']),
                  switchMap((code) => this.authService.getToken(code)),
                  map((token) => this.authService.setToken(token))
              )
              //.subscribe(() => this.router.navigate(['/home']))
              .subscribe(() => {
                this.authService.getOtherToken()
                .pipe(
                    map((otherToken) => this.authService.setOtherToken(otherToken))
                )
                .subscribe(() => this.router.navigate(['/home']))
              })
      );
  }

  ngOnDestroy(): void {
      this.subscription.forEach((value) => value.unsubscribe());
  }

}
