// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const federationGateway = {
  baseUrl: '{endPointBaseUri}',
  redirectUri:`${window.location.protocol}//${window.location.host}/login-callback`,
  authURL: 'auth/realms/{realm}/protocol/openid-connect',
};

export const environment = {
  logLevel: 'debug',
  production: false,
  auth_cookie_domain: 'scania.com',
  auth_cookie: 'authorization',
  refresh_cookie: 'authorizationRefresh',
  otherAuth_cookie: 'otherAuthorization',
  tokenUri_key: 'tokenUri',
  otherTokenUri_key: 'otherTokenUri',
  clientId_key: 'clientId',
  refreshTokenUri_key: 'refreshtokenUri',
  endPointBaseUri_key: 'endpointBaseUri',
  realm_key: 'realm',
  authorizerUri: 'https://sla-auth-example.sla01.devtest.aws.scania.com/api/config',
  authUri: `${federationGateway.baseUrl}/${federationGateway.authURL}/auth?client_id={client_id}&response_type=code&scope=openid&redirect_uri=${federationGateway.redirectUri}&nonce=foo`,
  redirectUri: federationGateway.redirectUri
};

